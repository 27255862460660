import React, { ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Button, Drawer, IconButton } from '@material-ui/core';

import css from './css/pageLayout.module.scss';
import sponsorLogo from '../assets/sponsor_logo.png';
import {
  ABOUT_TEST_LINK,
  FUND_WEBSITE_LINK,
  LOGO_LINK,
  NAKED_HEART_ONLINE_LINK,
  PRIVACY_POLICY_LINK,
  SPONSOR_LOGO_LINK,
} from '../config/links';
import { ChevronRight, Close, Logo, Menu } from './Icon';
import { TEST, TEST_ABOUT } from '../routes';


export default function PageLayout(props: {
  className?: string, title?: ReactNode, children: ReactNode, withAppBar?: boolean, showFooterLineText?: boolean,
}) {
  const history = useHistory();
  const {
    className,
    title = 'СКРИНИНГОВЫЙ ТЕСТ НА АУТИЗМ M-CHAT-R',
    children,
    withAppBar = true,
    showFooterLineText = false
  } = props;
  const [isDrawerOpen, setDrawerOpen] = useState(!withAppBar);
  const handleMenuClick = useCallback(() => {
    setDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen, setDrawerOpen]);
  const handleClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);
  const goAboutPage = useCallback(() => {
    if (history.location.pathname === TEST_ABOUT) return;
    history.push(TEST_ABOUT);
  }, [history]);
  const handleAboutClickFromDrawer = useCallback(() => {
    handleClose();
    goAboutPage();
  }, [goAboutPage, handleClose]);

  return (
    <div className={classnames(css.PageLayout, className)}>
      <AppBar isDrawerOpen={isDrawerOpen} onAboutClick={goAboutPage} onMenuClick={handleMenuClick}>
        <PageTitle>{title}</PageTitle>
      </AppBar>
      <div className={css.PageLayout__title}><PageTitle>{title}</PageTitle></div>
      <div className={css.content}>{children}</div>
      <Footer showLineText={showFooterLineText} />

      <Drawer
        className={css.Drawer} classes={{ paper: css.paper }} anchor='right' open={isDrawerOpen}
        onClose={handleClose}
      >
        <div className={classnames(css.PageLayout, className)}>
          <AppBar className={classnames(css.hidden)} isDrawerOpen onMenuClick={handleMenuClick} />
          <div className={css.content}>
            <div className={css.Drawer__actions}>
              <AppBarButton href={ABOUT_TEST_LINK} onClick={handleAboutClickFromDrawer}>о тесте</AppBarButton>
              <AppBarButton href={NAKED_HEART_ONLINE_LINK}>обнаженные сердца онлайн</AppBarButton>
            </div>
          </div>
          <Footer />
        </div>
      </Drawer>
    </div>
  );
}

function AppBar({ className, children, isDrawerOpen = false, onMenuClick, onAboutClick }: {
  className?: string, children?: ReactNode, isDrawerOpen?: boolean, onMenuClick?: () => void, onAboutClick?: () => void
}) {
  return (
    <div className={classnames(css.AppBar, className)}>
      <div className={css.AppBar__logoWrapper}>
        <a href={LOGO_LINK} target="_blank" rel="noreferrer">
          <Logo />
          {/*<ImageWrap src={logo} alt="logo" />*/}
        </a>
      </div>
      <div style={{ flex: 1 }} />
      <div className={css.AppBar__content}>{children}</div>
      <div className={css.AppBar__actions}>
        <AppBarButton href={ABOUT_TEST_LINK} onClick={onAboutClick}>о тесте</AppBarButton>
        <AppBarButton href={NAKED_HEART_ONLINE_LINK}>обнаженные сердца онлайн</AppBarButton>
      </div>
      <div className={css.AppBar__menu}>
        <IconButton className={css.AppBar__menu__btn} onClick={onMenuClick}>
          {!isDrawerOpen ? <Menu /> : <Close />}
        </IconButton>
      </div>
    </div>
  );
}

function PageTitle({ className, children, }: { className?: string, children: ReactNode }) {
  const history = useHistory();
  const goWelcome = useCallback(() => {
    history.push(TEST);
  }, [history]);
  return (<div className={classnames(css.PageTitle, className)} onClick={goWelcome}>{children}</div>);
}

function Footer({ className, showLineText = false }: { className?: string, showLineText?: boolean }) {
  return (
    <div className={classnames(css.Footer, className)}>
      {showLineText && (
        <div className={css.Footer__lineText}>
          <span>Мы не сохраняем себе данные теста и они полностью конфиденциальны</span>
        </div>
      )}

      <div className={css.Footer__content}>
        <div>
          <div className={classnames(css.Footer__content__corp2, css.accent)}>
            <span>{'© 2009 Diana Robins, \nDeborah Fein, & Marianne Barton'}</span>
          </div>
          <div className={css.Footer__content__info}>
            <span>
              {'Фонд помощи детям и молодежи «Обнажённые сердца» – благотворительная организация, помогающая людям с аутизмом и другими нарушениями в развитии, основанная Натальей Водяновой в 2004 году.'}
              <a href={FUND_WEBSITE_LINK} target="_blank" rel="noreferrer"
                 className={classnames(css.fundLink, css.accent)}>
              <span>{'Перейти на сайт Фонда'}</span>
              <ChevronRight />
            </a>
            </span>
          </div>
          <div className={css.Footer__content__fond}>
            <span className={classnames(css.accent)}>© 2021 Фонд «Обнажённые сердца»</span>
            &#8203;
            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
              <span>Политика конфиденциальности</span>
            </a>
          </div>
        </div>
        <a className={css.sponsor} href={SPONSOR_LOGO_LINK} target="_blank" rel="noreferrer">
          <span>При поддержке бренда</span>
          <ImageWrap className={css.sponsor__logo} src={sponsorLogo} alt={'sponsor-logo'} />
        </a>
      </div>
    </div>
  );
}

export function ImageWrap({ className, src, alt }: { className?: string, src: string, alt?: string }) {
  return (<div className={classnames(css.ImageWrap, className)}><img src={src} alt={alt} /></div>);
}

function AppBarButton({ children, onClick, href }: { children: ReactNode, href?: string, onClick?: () => void }) {
  let refProps = {};
  if (href) refProps = {
    href,
    target: '_blank',
    referrerPolicy: 'no-referrer',
  };
  return (
    <Button className={css.AppBarButton} variant="contained" color="primary" onClick={onClick} {...refProps}>
      {children}
    </Button>
  );
}
