import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import classnames from 'classnames';

import css from './resultPage.module.scss';
import PageLayout from '../../components/PageLayout';
import ContentLayout, { Card, CardContent, CardSubtitle, CardTitle } from '../../components/ContentLayout';
import imageLeft from '../../assets/illustration_left.png';
import imageRight from '../../assets/illustration_right.png';
import { DownloadButton, RoundedButton, StartAgainButton } from '../../components/Buttons';
import ProgressBar from '../../components/ProgressBar';
import { questionAnswersSelector, questionsSelector, resultSelector } from '../questionsSlice';
import PdfResult from './PdfResult';
import { FUND_WEBSITE_LINK } from '../../config/links';

export default function ResultPage({ onAgain }: { onNext?: () => void, onAgain?: () => void }) {
  const questions = useSelector(questionsSelector);
  const answers = useSelector(questionAnswersSelector);
  const result = useSelector(resultSelector);
  const { title, description } = result;
  const [showWhatNext, setShowWhatNext] = useState(false);

  const handleShowNextClick = useCallback(() => {
    setShowWhatNext(true);
  }, []);

  return (
    <PageLayout className={css.ResultPage}>
      <ContentLayout
        imageLeft={imageLeft} imageRight={imageRight}
        bottom={(
          <div className={css.ResultPage__bottom}>
            <ProgressBar value={100} />
          </div>
        )}
      >
        {!showWhatNext && (
          <>
            <Card className={classnames(css.ResultPage__card, { [css.large]: result.large })}>
              <CardSubtitle>Результат теста</CardSubtitle>
              <CardTitle>{title}</CardTitle>
              <CardContent>{description}</CardContent>
            </Card>
            {/*<RoundedButton onClick={handleShowNextClick}>ЧТО ДАЛЬШЕ?</RoundedButton>*/}
          </>
        )}
        {showWhatNext && (
          <>
            <Card className={css.ResultPage__whatNextCard}>
              <CardSubtitle>Что дальше</CardSubtitle>
              <CardContent>
                Свяжитесь с организацией, созданной родителями детей с аутизмом в вашем регионе. Наиболее компетентными
                в вопросах помощи детям с РАС сегодня являются родители, которых эта проблема коснулась несколько лет
                назад. Получите информацию о том, где вы можете получить современную диагностику расстройств
                аутистического спектра и квалифицированную помощь.
              </CardContent>
            </Card>
            <RoundedButton onClick={handleShowNextClick} href={FUND_WEBSITE_LINK}>Перейти на сайт</RoundedButton>
          </>
        )}
        <div className={css.ResultPage__actions}>
          <StartAgainButton onClick={onAgain} />
          <PDFDownloadLink
            className={css.downloadLink}
            document={<PdfResult questions={questions} answers={answers} result={result} />}
            fileName="ТЕСТ M-CHAT-R.pdf"
          >
            {({ blob, url, loading, error }) => <DownloadButton />}
          </PDFDownloadLink>
        </div>
      </ContentLayout>
    </PageLayout>
  );
}
