import React from 'react';

import PageLayout from '../../components/PageLayout';
import ContentLayout, { Card, CardContent } from '../../components/ContentLayout';
import imageLeft from '../../assets/illustration_left.png';
import imageRight from '../../assets/illustration_right.png';
import css from './welcomePage.module.scss';
import { RoundedButton } from '../../components/Buttons';
import { Question } from '../../components/Icon';
import { plural } from '../../utils';

export default function WelcomePage({ onNext }: { onNext?: () => void }) {
  const questionsCount = 20;
  return (
    <PageLayout className={css.WelcomePage}>
      <ContentLayout imageLeft={imageLeft} imageRight={imageRight}>
        <Card className={css.card}>
          {/*<CardTitle>ТЕСТ M-CHAT-R</CardTitle>*/}
          <CardContent className={css.text}>
            Вы можете пройти бесплатный онлайн-тест M-CHAT-R на выявление у ребенка первых признаков аутизма в возрасте
            от 18 месяцев до 2–2,5 лет. Короткий опросник заполняется за несколько минут, но может показать, есть ли
            повод для обращения к специалистам.
          </CardContent>
          <div className={css.questions}>
            <Question />
            <span>{`${questionsCount} ${plural(questionsCount, 'Вопрос', 'Вопроса', 'Вопросов')}`}</span>
          </div>
        </Card>
        <RoundedButton className={css.nextBtn} onClick={onNext}>ПРОЙТИ ТЕСТ</RoundedButton>
        <div className={css.bottom}>
          <span>Мы не сохраняем себе данные теста, <br />и они полностью конфиденциальны</span>
        </div>
      </ContentLayout>
    </PageLayout>
  );
}
