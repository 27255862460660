import { DependencyList, useEffect } from 'react';

type WindowEventType = keyof WindowEventMap;
type WindowEventListener<K extends WindowEventType> = (this: Window, ev: WindowEventMap[K]) => any;

export function useWindowEvent<K extends WindowEventType>(
  type: K, listener: WindowEventListener<K>, deps?: DependencyList
) {
  useEffect(() => {
    if (listener == null) return;

    const callback: WindowEventListener<K> = function (e) {
      return listener.call(this, e);
    }
    window.addEventListener(type, callback);
    callback.call(window, {} as any);
    return () => window.removeEventListener(type, callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? [type]);
}
