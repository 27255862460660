import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import css from './questionsPage.module.scss';
import PageLayout from '../../components/PageLayout';
import ContentLayout, { Card, CardContent, CardSubtitle, CardTitle } from '../../components/ContentLayout';
import imageLeft from '../../assets/illustration_left.png';
import imageRight from '../../assets/illustration_right.png';
import { BackButton, NoButton, YesButton } from '../../components/Buttons';
import { Question } from '../../config/models';
import ProgressBar from '../../components/ProgressBar';
import CardStack, { ControlsActions } from '../../components/CardStack';
import { useWindowEvent } from '../../hooks/windowHooks';
import {
  initTest,
  nextQuestion,
  prevQuestion,
  progressSelector,
  questionsCountSelector,
  questionsSelector,
  selectAnswer,
  selectedQuestionIndexSelector,
  selectedQuestionSelector
} from '../questionsSlice';

export default function QuestionsPage({ onNext, onBack }: { onNext?: () => void, onBack?: () => void }) {
  const dispatch = useDispatch();
  const questions = useSelector(questionsSelector);
  const questionsCount = useSelector(questionsCountSelector);
  const index = useSelector(selectedQuestionIndexSelector) ?? 0;
  const q = useSelector(selectedQuestionSelector);
  const progress = useSelector(progressSelector);

  const ignoreActions = useRef<boolean>(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const cardListRef = useRef<HTMLUListElement>(null);
  const controlsRef = useRef<ControlsActions>(null);

  const handleNext = useCallback(async (value: boolean) => {
    await dispatch(selectAnswer(value));
    if (index < questionsCount - 1) {
      dispatch(nextQuestion());
    } else if (onNext != null) {
      setTimeout(onNext, 450);
    }
  }, [index, questionsCount, onNext, dispatch]);
  const handleYesClick = useCallback(() => {
    if (ignoreActions.current) return;
    ignoreActions.current = true;
    controlsRef.current?.accept(() => ignoreActions.current = false);
    handleNext(true);
  }, [handleNext, controlsRef]);
  const handleNoClick = useCallback(() => {
    if (ignoreActions.current) return;
    ignoreActions.current = true;
    controlsRef.current?.reject(() => ignoreActions.current = false);
    handleNext(false);
  }, [handleNext, controlsRef]);

  const handleBackClick = useCallback(() => {
    if (ignoreActions.current) return;
    if (index > 0) {
      controlsRef.current?.back();
      dispatch(prevQuestion());
    } else onBack && onBack();
  }, [index, onBack, dispatch]);

  const handleResize = () => {
    if (cardRef.current == null || cardListRef.current == null) return;
    const { clientWidth: width, clientHeight: height } = cardRef.current;
    cardListRef.current.style.width = `${width}px`;
    cardListRef.current.style.height = `${height}px`;
  };
  useWindowEvent('resize', handleResize, [cardRef, cardListRef, index]);

  useEffect(() => {
    dispatch(initTest());
    const timeout = setTimeout(handleResize, 100);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  if (q == null) return null;

  return (
    <PageLayout className={css.QuestionPage}>
      <ContentLayout
        className={css.ContentLayout}
        imageLeft={q?.illustrationLeft || imageLeft} imageRight={q?.illustrationRight || imageRight}
        bottom={(
          <div className={css.QuestionPage__bottom}>
            <div><BackButton className={css.QuestionPage__bottom__back} onClick={handleBackClick} /></div>
            <ProgressBar value={progress} />
          </div>
        )}
      >
        <div className={css.QuestionPage__content}>
          <QuestionCard
            ref={cardRef} className={css.hiddenCard} item={q} index={index} questionsCount={questionsCount}
          />
          <CardStack
            className={css.cardStack}
            listRef={cardListRef}
            controls={controlsRef}
            cards={
              questions.map((q, i) => {
                const className = `item_${i - index}`;
                return (
                  <QuestionCard key={i} className={className} item={q} index={i} questionsCount={questionsCount} />
                );
              })
            }
          />
          <div className={css.QuestionPage__actions}>
            <YesButton onClick={handleYesClick} />
            <NoButton onClick={handleNoClick} />
          </div>

          <span className={css.QuestionPage__hint}>
            Если Вы замечали у ребёнка поведение несколько раз,
             но обычно он/она так себя не ведёт, то, пожалуйста, ответьте «нет»
          </span>
        </div>
      </ContentLayout>
    </PageLayout>
  );
}


type QuestionCardProps = {
  className?: string, item: Question, index: number, questionsCount: number
};
const QuestionCard = forwardRef<HTMLDivElement, QuestionCardProps>((
  { className, item, index, questionsCount }, ref) => {
  const { question, description } = item;
  return (
    <Card ref={ref} className={classnames(css.QuestionPage__card, className)}>
      <CardSubtitle className={css.number}>{`${index + 1} ИЗ ${questionsCount}`}</CardSubtitle>
      <CardTitle className={classnames({ small: !!description || question.length > 110 })}>{question}</CardTitle>
      {description && <CardContent>{description}</CardContent>}
    </Card>
  );
});
