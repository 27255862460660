import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Button as MuiButton } from '@material-ui/core';

import css from './css/buttons.module.scss';
import { ChevronLeft, Download, NoIcon, YesIcon } from './Icon';

export type Props = {
  className?: string, children?: ReactNode, onClick?: () => void, href?: string,
};

export function RoundedButton({ className, href, children, onClick }: Props) {
  let refProps = {};
  if (href) refProps = {
    href,
    target: '_blank',
    referrerPolicy: 'no-referrer',
  };
  return (
    <MuiButton className={classnames(css.RoundedButton, className)} variant="contained" {...refProps} onClick={onClick}>
      {children}
    </MuiButton>
  );
}

export function Button({ className, children, onClick }: Props) {
  return (
    <MuiButton className={classnames(css.Button, className)} variant="contained" color="primary" onClick={onClick}>
      {children}
    </MuiButton>
  );
}


export function YesButton({ className, ...props }: Props) {
  return (<RoundedButton className={classnames(css.YesButton, className)} {...props}><YesIcon />ДА</RoundedButton>);
}

export function NoButton({ className, ...props }: Props) {
  return (<RoundedButton className={classnames(css.NoButton, className)} {...props}><NoIcon />НЕТ</RoundedButton>);
}

export function BackButton(props: Props) {
  return (<Button {...props}><ChevronLeft />НАЗАД</Button>);
}

export function StartAgainButton({ className, ...props }: Props) {
  return (<Button className={classnames(css.medium, className)} {...props}><ChevronLeft />ПРОЙТИ ТЕСТ ЕЩЕ РАЗ</Button>);
}

export function DownloadButton({ className, ...props }: Props) {
  return (<Button className={classnames(css.medium, css.DownloadButton, className)} {...props}>
    <Download />СКАЧАТЬ РЕЗУЛЬТАТ
  </Button>);
}
