import React, { forwardRef, ReactNode } from 'react';
import classnames from 'classnames';

import css from './css/contentLayout.module.scss';
import { ImageWrap } from './PageLayout';


export default function ContentLayout({ className, children, imageLeft, imageRight, bottom }: {
  className?: string, children: ReactNode, bottom?: ReactNode, imageLeft: string, imageRight: string,
}) {
  return (
    <div className={classnames(css.ContentLayout, className)}>
      <div className={classnames(css.ContentLayout__content)}>
        <div className={classnames(css.ContentLayout__bg, 'background')}>
          <ImageWrap className={css.ContentLayout__bg_image} src={imageLeft} />
          <div className={classnames(css.ContentLayout__bg_spacer, 'background__spacer')} />
          <ImageWrap className={css.ContentLayout__bg_image} src={imageRight} />
        </div>
        {children}
      </div>
      {/*{children}*/}
      {bottom}
    </div>
  );
}


export const Card = forwardRef<HTMLDivElement, { className?: string, children: ReactNode }>((
  { className, children }, ref
) => {
  return (<div ref={ref} className={classnames(css.Card, className)}>{children}</div>);
});


export function CardTitle({ className, children }: { className?: string, children: string }) {
  return (<h1 className={classnames(css.CardTitle, className)}>{children}</h1>);
}

export function CardSubtitle({ className, children }: { className?: string, children: string }) {
  return (<h3 className={classnames(css.CardSubtitle, className)}>{children}</h3>);
}

export function CardContent({ className, children }: { className?: string, children: ReactNode }) {
  return (<p className={classnames(css.CardContent, className)}>{children}</p>);
}
