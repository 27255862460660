import React, { MutableRefObject, ReactChild, ReactNode, useCallback, useEffect, useRef } from 'react';

import '../libs/cardStack/modernizr-custom';
import '../libs/cardStack/stack';
import '../libs/cardStack/stack.css';

import classnames from 'classnames';

export type ControlsActions = {
  reject: (callback?: () => void) => void,
  accept: (callback?: () => void) => void,
  back: () => void,
  restart: () => void,
};

export type Props = {
  className?: string,

  cards: ReactChild[],
  effect?: string,
  visible?: number,
  listRef?: MutableRefObject<HTMLUListElement | null>,
  controls?: MutableRefObject<ControlsActions | null>,

  renderControls?: (props: ControlsActions) => ReactNode,

  infinite?: boolean,

  onEndStack?: () => void,
}
export default function CardStack(props: Props) {
  const itemRef = useRef<HTMLUListElement>(null);
  const stack = useRef<any>();
  const {
    className, cards, effect = 'krisna', infinite = false, visible = 3, renderControls, onEndStack, listRef, controls,
  } = props;

  const handleReject = useCallback(() => {
    stack.current.reject();
  }, [stack]);
  const handleAccept = useCallback(() => {
    stack.current.accept();
  }, [stack]);
  const handleBack = useCallback(() => {
    stack.current.back();
  }, [stack]);
  const handleRestart = useCallback(() => {
    stack.current.restart();
  }, [stack]);

  useEffect(() => {
    const { Stack } = window as any;
    const options = { infinite, visible } as any;
    if (onEndStack != null) options.onEndStack = onEndStack;
    stack.current = new Stack(itemRef.current, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listRef == null) return;
    listRef.current = itemRef.current;
  }, [itemRef, listRef]);
  useEffect(() => {
    if (controls == null) return;
    controls.current = stack.current;
  }, [controls, stack]);
  return (
    <div className={classnames('stack-container', className)}>
      <ul ref={itemRef} className={classnames('stack', `stack--${effect}`)}>
        {cards.map((c, i) => (
          <li key={i} className="stack__item">
            {c}
          </li>
        ))}
      </ul>
      {renderControls && (<div className="controls">
        {renderControls({ reject: handleReject, accept: handleAccept, back: handleBack, restart: handleRestart })}
      </div>)}
    </div>
  );
}
