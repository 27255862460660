import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Question, questions, Result, results } from '../config/models';
import { RootState } from '../app/store';


type Answer = boolean;

interface QuestionsState {
  inited: boolean;
  questions: Question[];
  selectedQuestionIndex: number | null;
  userAnswers: (Answer | null)[];
  result: Result;
}

const initialState: QuestionsState = {
  inited: false,
  questions: [],
  selectedQuestionIndex: null,
  userAnswers: [],
  result: results[0],
};

export const questionsSlice = createSlice({
  name: 'test_questions',
  initialState,
  reducers: {
    initTest: state => {
      state.inited = true;
      state.questions = questions;
      state.selectedQuestionIndex = questions?.length ? 0 : null;
      state.userAnswers = [];
      state.result = results[0];
    },
    nextQuestion: state => {
      if (!canGoNextQuestion(state)) return;
      const ind = state.selectedQuestionIndex! += 1;
      state.userAnswers[ind] = null;
    },
    prevQuestion: state => {
      if (!canGoPrevQuestion(state)) return;
      const ind = state.selectedQuestionIndex! -= 1;
      state.userAnswers[ind] = null;
    },
    selectAnswer: (state, action: PayloadAction<Answer>) => {
      if (getUserAnswer(state) != null) return;
      const ind = state.selectedQuestionIndex || 0;
      state.userAnswers[ind] = action.payload;
      if (ind >= state.questions.length - 1) {
        state.result = calcResult(state);
      }
    },
  },
});

function canGoNextQuestion(state: QuestionsState): boolean {
  const { selectedQuestionIndex, questions } = state;
  const ind = selectedQuestionIndex || 0;
  const length = questions?.length || 0;
  if (!(length && length > ind! + 1)) return false;
  return getUserAnswer(state, ind) != null;
}

function canGoPrevQuestion(state: QuestionsState): boolean {
  return true;
}

function getQuestionScore(q: Question, answer: Answer): number {
  let risk = !answer;
  if (q.inverted) risk = !risk;
  return risk ? 1 : 0;
}

function calcRiskScore(state: QuestionsState): number {
  const { questions } = state;
  let score = 0;
  for (let i = 0; i < questions.length; i++) {
    const q = questions[i];
    score += getQuestionScore(q, getUserAnswer(state, i) ?? false);
  }
  return score;
}

function calcResult(state: QuestionsState): Result {
  const score = calcRiskScore(state);
  let result = results[0];
  for (let i = 1; i < results.length; i++) {
    const r = results[i];
    if (score >= r.from) result = r;
    if (score <= r.to) break;
  }
  return { ...result, score } as Result;
}

function getUserAnswer(state: QuestionsState, index: number | undefined = undefined): Answer | null {
  const { userAnswers, selectedQuestionIndex } = state;
  if (index == null) index = selectedQuestionIndex || 0;
  if (userAnswers.length > index) return userAnswers[index];
  return null;
}

export const { initTest, selectAnswer, nextQuestion, prevQuestion } = questionsSlice.actions;


const rootSelector = (state: RootState) => state.questions;

export const initedSelector = (state: RootState) => rootSelector(state).inited;
export const questionsSelector = (state: RootState) => rootSelector(state).questions ?? [];
export const questionsCountSelector = (state: RootState) => questionsSelector(state)?.length ?? 0;
export const selectedQuestionIndexSelector = (state: RootState) => rootSelector(state).selectedQuestionIndex;
export const selectedQuestionSelector = (state: RootState) => {
  const ind = selectedQuestionIndexSelector(state);
  if (ind == null) return null;
  return questionsSelector(state)[ind];
};
export const questionAnswersSelector = (state: RootState) => rootSelector(state).userAnswers ?? [];
export const selectedQuestionAnswerSelector = (state: RootState) => getUserAnswer(rootSelector(state));
export const progressSelector = (state: RootState) => {
  const questionsCount = questionsCountSelector(state);
  const index = selectedQuestionIndexSelector(state) ?? 0;
  const selectedAnswer = selectedQuestionAnswerSelector(state);
  return (index + (selectedAnswer == null ? 0 : 1)) / questionsCount * 100;
};
export const resultSelector = (state: RootState) => rootSelector(state).result;

const reducer = { questions: questionsSlice.reducer };
export default reducer;
