export interface Question {
  id: number;
  question: string;
  description?: string;
  hint?: string;
  inverted?: boolean;

  illustrationLeft?: string;
  illustrationRight?: string;
}

export interface Result {
  from: number;
  to: number;
  large?: boolean;
  title: string;
  description: string;
}

// Ответ "НЕТ" по всем пунктам, за исключением 2, 5 и 12, указывает на риск РАС;
// ответ "ДА" для пунктов 2, 5 и 12 указывает на риск РАС.

export const questions: Question[] = [
  {
    id: 1,
    question: 'Если вы указываете пальцем на что-то в другом конце комнаты, ваш ребенок на это смотрит?',
    description: 'Пример: Если вы указываете пальцем на игрушку или животное, ребенок посмотрит на игрушку или животное?',
    illustrationLeft: '/questions/illustration_left_1.svg',
    illustrationRight: '/questions/illustration_right_1.svg',
  }, {
    id: 2,
    question: 'Вы когда-нибудь предполагали, что Ваш ребёнок может быть глухим?',
    inverted: true,
    illustrationLeft: '/questions/illustration_left_2.svg',
    illustrationRight: '/questions/illustration_right_2.svg',
  }, {
    id: 3,
    question: 'Ваш ребенок играет в воображаемые или сюжетно-ролевые игры?',
    description: 'Пример: Ребенок притворяется, что пьет из пустой чашки, делает вид, что говорит по телефону, понарошку кормит куклу или плюшевую игрушку',
    illustrationLeft: '/questions/illustration_left_3.svg',
    illustrationRight: '/questions/illustration_right_3.svg',
  }, {
    id: 4,
    question: 'Ребенку нравится забираться на различные предметы?',
    description: 'Пример: Ребенок любит залезать на мебель, на оборудование детской площадки',
    illustrationLeft: '/questions/illustration_left_4.svg',
    illustrationRight: '/questions/illustration_right_4.svg',
  }, {
    id: 5,
    question: 'Ваш ребенок делает необычные движения пальцами перед глазами?',
    description: 'Пример: Ребенок перебирает пальцами около глаз',
    inverted: true,
    illustrationLeft: '/questions/illustration_left_5.svg',
    illustrationRight: '/questions/illustration_right_5.svg',
  }, {
    id: 6,
    question: 'Ребенок указывает пальцем, чтобы что-то попросить или получить помощь?',
    description: 'Пример: Ребенок указывает пальцем на лакомство или игрушку, до которой не может дотянуться',
    illustrationLeft: '/questions/illustration_left_6.svg',
    illustrationRight: '/questions/illustration_right_6.svg',
  }, {
    id: 7,
    question: 'Ребенок указывает пальцем, чтобы обратить внимание на что-то интересное?',
    description: 'Пример: Ребенок указывает пальцем на самолет в небе или на большой грузовик на дороге',
    illustrationLeft: '/questions/illustration_left_7.svg',
    illustrationRight: '/questions/illustration_right_7.svg',
  }, {
    id: 8,
    question: 'Ребенок интересуется другими детьми?',
    description: 'Пример: Ребенок наблюдает за другими детьми, улыбается им, идет к ним',
    illustrationLeft: '/questions/illustration_left_8.svg',
    illustrationRight: '/questions/illustration_right_8.svg',
  }, {
    id: 9,
    question: 'Ребенок приносит предметы и поднимает их, чтобы показать вам?',
    description: 'Пример: Ребенок показывает вам цветок, плюшевую игрушку или машинку',
    illustrationLeft: '/questions/illustration_left_9.svg',
    illustrationRight: '/questions/illustration_right_9.svg',
  }, {
    id: 10,
    question: 'Ребенок отзывается на свое имя?',
    description: 'Пример: Он или она смотрит на вас, говорит или лепечет, прекращает то, что сейчас делает, когда вы зовете его или ее по имени',
    illustrationLeft: '/questions/illustration_left_10.svg',
    illustrationRight: '/questions/illustration_right_10.svg',
  }, {
    id: 11,
    question: 'Когда вы улыбаетесь ребенку, он или она улыбается в ответ?',
    illustrationLeft: '/questions/illustration_left_11.svg',
    illustrationRight: '/questions/illustration_right_11.svg',
  }, {
    id: 12,
    question: 'Ребенка расстраивают обычные звуки?',
    description: 'Пример: Ребенок начинает кричать или плакать от таких звуков, как шум пылесоса или громкая музыка',
    inverted: true,
    illustrationLeft: '/questions/illustration_left_12.svg',
    illustrationRight: '/questions/illustration_right_12.svg',
  }, {
    id: 13,
    question: 'Ваш ребёнок \nумеет ходить?',
    illustrationLeft: '/questions/illustration_left_13.svg',
    illustrationRight: '/questions/illustration_right_13.svg',
  }, {
    id: 14,
    question: 'Ребенок смотрит вам в глаза, когда вы говорите с ним, играете или одеваете?',
    illustrationLeft: '/questions/illustration_left_14.svg',
    illustrationRight: '/questions/illustration_right_14.svg',
  }, {
    id: 15,
    question: 'Ребенок пытается копировать то, что вы делаете?',
    description: 'Пример: Машет рукой в ответ, когда вы прощаетесь, хлопает в ладоши, издает смешные звуки',
    illustrationLeft: '/questions/illustration_left_15.svg',
    illustrationRight: '/questions/illustration_right_15.svg',
  }, {
    id: 16,
    question: 'Если вы обернетесь и посмотрите на что-нибудь, ваш ребенок обернется, чтобы увидеть, на что вы смотрите?',
    illustrationLeft: '/questions/illustration_left_16.svg',
    illustrationRight: '/questions/illustration_right_16.svg',
  }, {
    id: 17,
    question: 'Ребенок старается сделать так, чтобы вы посмотрели на него?',
    description: 'Пример: Ребенок смотрит на вас, ожидая похвалу, или говорит «гляди» или «посмотри на меня»',
    illustrationLeft: '/questions/illustration_left_17.svg',
    illustrationRight: '/questions/illustration_right_17.svg',
  }, {
    id: 18,
    question: 'Ребенок понимает то, что вы говорите ему или ей?',
    description: 'Пример: Если вы не будете ничего показывать руками, ребенок поймет такие фразы как «положи книжку на стул» или «принеси мне одеяло»',
    illustrationLeft: '/questions/illustration_left_18.svg',
    illustrationRight: '/questions/illustration_right_18.svg',
  }, {
    id: 19,
    question: 'Когда случается что-то необычное, ребенок смотрит на ваше лицо, чтобы понять, как вы к этому относитесь?',
    description: 'Пример: Если ребенок слышит странный или непривычный звук или видит новую игрушку, он или она смотрит на ваше лицо',
    illustrationLeft: '/questions/illustration_left_19.svg',
    illustrationRight: '/questions/illustration_right_19.svg',
  }, {
    id: 20,
    question: 'Вашему ребенку нравятся подвижные занятия?',
    description: 'Пример: Когда ребенок подпрыгивает у вас на коленях или когда его раскачивают',
    illustrationLeft: '/questions/illustration_left_20.svg',
    illustrationRight: '/questions/illustration_right_20.svg',
  },
];

export const results: Result[] = [
  {
    // 'Количество баллов 0-2;',
    from: 0,
    to: 2,
    title: 'НИЗКИЙ РИСК',
    description: 'Если ребёнок младше 24 месяцев, то проведите повторное тестирование по достижению им 2 лет. Никаких дополнительных действий не требуется, если наблюдение не выявляет риск РАС.',
  },
  {
    // 'Количество баллов 3-7;',
    from: 3,
    to: 7,
    large: true,
    title: 'СРЕДНИЙ РИСК',
    description: 'Рекомендуется пройти дополнительное интервью (M-CHAT-R/F), чтобы собрать более точную информацию о реакциях ребенка, указывающих на повышенный риск. В этом случае повторный скрининг проводится педиатром или другим специалистом в виде интервью с родителями. \n' +
      '\n' +
      'Такой формат помогает в некоторых случаях разъяснить родителям вопросы скрининга и уточнить корректность ответов на них, чтобы избежать избыточного направления на диагностику по результатам проведенного скрининга.\n' +
      '\n' +
      'Если нет возможности провести интервью с использованием M-CHAT-R/F, то считается, что у ребенка положительный результат теста. Требуется направление ребенка на обследование для диагностики и отбора в программы раннего вмешательства. \n',
  },
  {
    // 'Количество баллов 8-20;',
    from: 8,
    to: 20,
    title: 'ВЫСОКИЙ РИСК',
    description: 'Рекомендуется немедленно направить ребенка на диагностическое обследование, а также обследование для отбора в программы раннего вмешательства.',
  },
];
