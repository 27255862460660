import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import css from './app.module.scss';
import { TEST, TEST_ABOUT, TEST_QUESTIONS, TEST_RESULT } from './routes';
import WelcomePage from './features/welcomePage/WelcomePage';
import QuestionsPage from './features/questionsPage/QuestionsPage';
import { isDebug } from './utils';
import ResultPage from './features/resultPage/ResultPage';
import { initTest } from './features/questionsSlice';
import CookiesPolicy from './components/CookiesPolicy';
import AboutPage from './features/aboutPage/AboutPage';

export default function App() {
  return (
    <div className={css.App}>
      <Router>
        <Test />
      </Router>
      <CookiesPolicy />
    </div>
  );
}

function Test() {
  const dispatch = useDispatch();
  const history = useHistory();
  const goWelcome = useCallback(() => {
    history.push(TEST);
  }, [history]);
  const goQuestions = useCallback(() => {
    dispatch(initTest());
    history.push(TEST_QUESTIONS);
  }, [history, dispatch]);
  const goResult = useCallback(() => {
    history.push(TEST_RESULT);
  }, [history]);

  useEffect(() => {
    if (!isDebug) history.replace(TEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      <Route exact path={TEST}>
        <WelcomePage onNext={goQuestions} />
      </Route>
      <Route path={TEST_QUESTIONS}>
        <QuestionsPage onNext={goResult} onBack={goWelcome} />
      </Route>
      <Route path={TEST_RESULT}>
        <ResultPage onAgain={goWelcome} />
      </Route>
      <Route path={TEST_ABOUT}>
        <AboutPage />
      </Route>
      <Redirect to={TEST} />
    </Switch>
  );
}
