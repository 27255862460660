import React, { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import classnames from 'classnames';

import { PRIVACY_POLICY_LINK } from '../config/links';
import css from './css/cookiesPolicy.module.scss';
import { Props as ButtonProps, RoundedButton } from './Buttons';
import { useDebounceValue } from '../hooks/debounce';

const cookiesKey = 'cookie_notice_accepted';

export default function CookiesPolicy() {
  const [{ [cookiesKey]: cookie }, setCookie] = useCookies([cookiesKey]);
  // const [cookie, setCookie] = useState<boolean>();
  const showComponent = cookie == null;
  const deleteComponent = useDebounceValue(!showComponent, { timeoutMs: 1000 });

  const handleChange = useCallback((value: boolean) => {
    setCookie(cookiesKey, value, { path: '/', expires: new Date(2121, 1) });
    // setCookie(value);
  }, [setCookie]);
  const handleAccept = useCallback(() => handleChange(true), [handleChange]);
  const handleReject = useCallback(() => handleChange(false), [handleChange]);

  if (deleteComponent) return null;
  return (
    <div className={classnames(css.CookiesPolicy, { [css.hidden]: !showComponent })}>
      <div className={css.CookiesPolicy__content}>
        <p>
          Продолжая использование сайта вы соглашаетесь с нашей политикой использования cookies. Вы можете ознакомиться
          с нашей политикой использования cookies <a href={PRIVACY_POLICY_LINK}><span>здесь</span></a>
        </p>
        <div className={css.CookiesPolicy__actions}>
          <Button onClick={handleAccept}>я согласен</Button>
          <Button onClick={handleReject}>я не согласен</Button>
        </div>
      </div>
    </div>
  );
}


export function Button({ className, ...props }: ButtonProps) {
  return (<RoundedButton className={classnames(css.btn, className)} {...props} />);
}
