import React, { ReactNode, useRef, useState } from 'react';
import classnames from 'classnames';
import { Popover } from '@material-ui/core';

import css from './aboutPage.module.scss';
import PageLayout from '../../components/PageLayout';
import ContentLayout, { Card, CardContent, CardTitle } from '../../components/ContentLayout';
import imageLeft from '../../assets/illustration_left.png';
import imageRight from '../../assets/illustration_right.png';


export default function AboutPage() {
  const testLink = 'https://mchatscreen.com';
  const earlyProgramLink = 'https://nakedheart.online/articles/programmy-pomoschi-detyam-s-autizmom';
  return (
    <PageLayout className={css.AboutPage}>
      <ContentLayout className={css.wrapper} imageLeft={imageLeft} imageRight={imageRight}>
        <Card className={css.AboutPage__card}>
          <CardTitle className={css.title}>О тесте</CardTitle>

          <Text>
            Опросники <TestInfo /> созданы для проведения скринингового обследования на выявление риска
            расстройств аутистического спектра (РАС) у детей в возрасте от 16 до 30 месяцев и основаны на ответах
            родителей / основных воспитателей ребенка (M–CHAT-R/F; Robins, Fein, & Barton, 2009).
          </Text>

          <Text>Опросники используются для детей в возрасте от 16 до 30 месяцев.</Text>

          <BorderedText>
            М-CHAT-R и М-CHAT-R/F не являются инструментами для постановки диагноза, это СКРИНИНГИ.
          </BorderedText>

          <Text>
            Ни один скрининговый инструмент не имеет 100%-й достоверности полученных данных. После проведения скрининга
            нужна консультация специалиста, владеющего методами диагностики расстройств аутистического спектра (РАС) и
            имеющего достаточный клинический опыт работы с детьми с РАС дошкольного возраста.
          </Text>

          <Text>
            М-CHAT-R и М-CHAT-R/F по сути представляют собой один и тот же скрининг, отличие заключается в том, что
            М-CHAT-R заполняется родителями самостоятельно, а М-CHAT-R/F заполняется родителями вместе с педиатром или
            другим специалистом, что помогает в некоторых случаях разъяснить родителям вопросы скрининга и уточнить
            корректность ответов на них. М-CHAT-R/F может использоваться для проведения интервью с теми родителями, чьи
            дети показали средний риск при заполнении М-CHAT-R, чтобы избежать избыточного направления на диагностику по
            результатам проведенного скрининга.
          </Text>

          <Subtitle>История создания</Subtitle>
          <Text>
            M-CHAT-R/F создан в США и является расширенной версией скринингового опросника CHAT, созданного в
            Великобритании. М-CHAT-R содержит 20 вопросов (в оригинальной версии содержится 9 вопросов). Данные о
            валидизации теста можно посмотреть <Link href="https://pubmed.ncbi.nlm.nih.gov/24366990/">здесь</Link>.
          </Text>
          <Text>
            Исследования, проведенные в различных странах мира, показали высокую валидность и надежность этого
            инструмента для определения риска расстройств аутистического спектра.
          </Text>
          <Text>
            Тест доступен для свободного скачивания и применения в клинических, исследовательских и образовательных
            целях.
          </Text>
          <Text>
            Русскоязычный перевод M-CHAT-R (Модифицированный скрининговый тест на аутизм для детей, пересмотренный) был
            одобрен авторами оригинального опросника.
          </Text>
          <Text>
            {'Опросник M-CHAT-R включен в Клинические рекомендации, утвержденные '}
            <Link href="https://cr.minzdrav.gov.ru/recomend/594_1">Министерством здравоохранения РФ</Link>.
          </Text>

          <Subtitle>Кто заполняет опросник</Subtitle>
          <Text>
            {'М - CHAT - R – опросник для родителей, они могут заполнить его '}
            <Link href="http://test.nakedheart.online/">здесь</Link>, ответив на вопросы, касающиеся поведения и
            реакций собственного ребенка.
          </Text>
          <Text>
            M-CHAT-R/F – опросник для проведения педиатрического скрининга специалистом.
          </Text>
          <Text>
            Интервью используется с теми родителями, чьи дети показали средний риск при заполнении М-CHAT-R, чтобы
            избежать избыточного направления на диагностику по результатам проведенного скрининга.
          </Text>
          <Text>
            Он включает в себя схему проведения скринингового интервью, представленную на страницах 6–28 документа,
            {'опубликованного '}
            <Link href="https://mchatscreen.com/wp-content/uploads/2019/12/M-CHAT-R_F_Russian_v3.pdf">здесь</Link>.
          </Text>

          <Subtitle>М-CHAT-R – Инструкция для применения</Subtitle>
          <Text>
            Пожалуйста, оцените, насколько приведенные ниже высказывания характеризуют вашего ребенка. Постарайтесь
            ответить на каждый вопрос. Если данное поведение проявлялось редко (вы видели это всего один или два раза),
            отметьте, что ребенку это не свойственно.
          </Text>
          <Text>Обработка данных М-CHAT-R занимает менее 2 минут.</Text>
          <Text>
            Дети, получившие более 3 баллов по общей шкале либо набравшие 2 критических балла, должны быть направлены на
            диагностическое обследование к специалистам, в чью компетенцию входит постановка диагноза РАС у детей
            раннего возраста.
          </Text>

          <Subtitle>Алгоритм по подсчету результатов</Subtitle>
          <Text>
            Для всех вопросов, за исключением вопросов 2, 5 и 12, ответ «нет» указывает на риск РАС. Для вопросов 2, 5 и
            12 ответ «да» указывает на риск РАС. Следующий алгоритм позволяет наиболее эффективно использовать
            психометрические возможности M-CHAT-R.
          </Text>

          <Subtitle className={css.resultTitle}>НИЗКИЙ РИСК</Subtitle>
          <Text>
            Общий результат – 0–2 балла. Если ребенок младше 24 месяцев, то рекомендуется повторное заполнение опросника
            после двух лет. Никаких дальнейших действий не требуется, если наблюдение не указывает на риск РАС.
          </Text>

          <Subtitle className={css.resultTitle}>Средний РИСК</Subtitle>
          <Text>
            Общий результат – 3–7 баллов. Если возможно, рекомендуется провести дополнительное интервью специалиста с
            родителями (M-CHAT-R/F), чтобы собрать более точную информацию о реакциях ребенка, указывающих на повышенный
            риск. В этом случае повторный скрининг проводится педиатром или другим специалистом в виде интервью с
            родителями, что помогает в некоторых случаях разъяснить родителям вопросы скрининга и уточнить корректность
            ответов на них, чтобы избежать избыточного направления на диагностику по результатам проведенного скрининга.
          </Text>
          <Text>
            Если результат теста M-CHAT-R/F останется выше 2 баллов (3–7 баллов) или нет возможности провести интервью с
            использованием M-CHAT-R/F, то считается, что у ребенка положительный результат теста. Требуется направление
            {'ребенка на обследование для диагностики и отбора в '}
            <Link href={earlyProgramLink}>программы раннего вмешательства</Link>.
          </Text>
          <Text>
            Если результат последующего интервью составляет 0–2 балла, то результат ребенка отрицательный. Никаких
            дальнейших действий не требуется, если наблюдение не указывает на риск РАС. Необходимо проводить повторное
            тестирование во время последующих визитов ребенка к педиатру или другому специалисту.
          </Text>

          <Subtitle className={css.resultTitle}>Высокий РИСК</Subtitle>
          <Text>
            Общий результат – 8–20 баллов. Рекомендуется немедленно направить ребенка на диагностическое обследование, а
            также обследование для отбора в <Link href={earlyProgramLink}>программы раннего вмешательства</Link>.
          </Text>

          <div className={css.divider} />

          <Text>
            <Link href={testLink}>{testLink}</Link> – официальная страница разработчиков инструмента
          </Text>
        </Card>
      </ContentLayout>
    </PageLayout>
  );
}

function Text({ children }: { children: ReactNode }) {
  return (<CardContent className={css.text}>{children}</CardContent>);
}

function BorderedText({ children }: { children: ReactNode }) {
  return (<div className={css.borderedText}>
    <div className={css.borderedText__divider} />
    {children}
    <div className={css.borderedText__divider} />
  </div>);
}

function Subtitle({ className, children }: { className?: string, children: ReactNode }) {
  return (<CardContent className={classnames(css.subtitle, className)}>{children}</CardContent>);
}

function Link({ href, children }: { href: string, children: ReactNode }) {
  return (<a href={href} target="_blank" rel="noreferrer" className={css.link}>{children}</a>);
}

function TestInfo() {
  const anchorEl = useRef<Element>(null);
  const [openState, setOpen] = useState({ open: false, byClick: false });

  const handlePopoverMouseEnterOpen = () => {
    if (!openState.open) setOpen({ open: true, byClick: false });
  };
  const handlePopoverMouseLeaveClose = () => {
    if (openState.open && !openState.byClick) setOpen({ open: false, byClick: false });
  };
  const handlePopoverToggle = () => {
    if (!openState.byClick) {
      setOpen({ open: true, byClick: true });
      return;
    }
    setOpen({ open: !openState.open, byClick: true });
  };
  const handlePopoverClose = () => {
    setOpen({ open: false, byClick: false });
  };

  return (
    <>
      <span
        aria-owns={openState ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        ref={anchorEl as any}
        className={css.testInfo}
        onClick={handlePopoverToggle}
        onMouseEnter={handlePopoverMouseEnterOpen}
        onMouseLeave={handlePopoverMouseLeaveClose}
      >
        M-CHAT-R и M-CHAT-R/F
      </span>
      <Popover
        id="mouse-over-popover"
        classes={{
          paper: css.testTooltip
        }}
        open={openState.open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={css.content}>
          <span className={css.arrow} />
          <span className={css.tooltipTitle}>M-CHAT-R – The Modified Checklist for Autism in Toddlers, Revised</span>
          <span>{'– модифицированный скрининговый тест на аутизм для детей, пересмотренный.'}</span>
          <div />
          <span
            className={css.tooltipTitle}>M-CHAT-R/F – The Modified Checklist for Autism in Toddlers, Revised, with <br />Follow-Up</span>
          <span> – модифицированный скрининговый тест на аутизм для детей, пересмотренный, с вопросами для последующего интервью</span>
        </div>
      </Popover>
    </>
  );
}
